import {
    AI_SURF_REPORT_REQUEST,
    AI_SURF_REPORT_SUCCESS,
    AI_SURF_REPORT_FAILURE,
    CLEAR_AI_DATA
  } from './fetchAISurfReport';
  
  const initialState = {
    aiSurfReport: null,
    aiSurfReportLoading: false,
    aiSurfReportError: null,
  };
  
  export function aiSurfReportReducer(state = initialState, action) {
    switch (action.type) {
      case AI_SURF_REPORT_REQUEST:
        return { ...state, aiSurfReportLoading: true, aiSurfReportError: null };
      case AI_SURF_REPORT_SUCCESS:
        return { ...state, aiSurfReport: action.payload, aiSurfReportLoading: false };
      case AI_SURF_REPORT_FAILURE:
        return { ...state, aiSurfReportError: action.payload, aiSurfReportLoading: false };
      case CLEAR_AI_DATA:
        return { ...initialState }; // Clear the state
      default:
        return state;
    }
  }
  