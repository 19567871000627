import { useSelector, useDispatch } from 'react-redux';
import { setUser, removeUser } from "../redux/userReducer";
import { useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Custom hook to manage authentication state
export const useAuth = () => {
  // Get user data from Redux state
  const { user: loggedInUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // State to track loading state during authentication check
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  // Check if a user is authenticated
  const isAuthenticated = !!loggedInUser;

  // Memoized function to update user data in Redux store
  const setUserInStore = useCallback((userData) => {
    const serializableUser = {
      uid: userData.uid,
      displayName: userData.displayName,
      email: userData.email,
      // ... other fields
    };
    dispatch(setUser(serializableUser));
  }, [dispatch]);

  // Memoized function to remove user data from Redux store
  const removeUserFromStore = useCallback(() => {
    dispatch(removeUser());
  }, [dispatch]);

  // Effect to listen for authentication state changes
  useEffect(() => {
    // Subscribe to the authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(false);
      if (user) {
        // User is authenticated
        if (!loggedInUser || user.uid !== loggedInUser.uid) {
          setUserInStore(user);
        }
      } else {
        // User is not authenticated
        if (loggedInUser) {
          removeUserFromStore();
        }
      }
    });

    // Clean up by unsubscribing when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [auth, setUserInStore, removeUserFromStore, loggedInUser]);

  // Return authentication status and loading state
  return { isAuthenticated, loading };
};
