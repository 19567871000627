import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container, CircularProgress } from '@mui/material'; 
import { styled } from '@mui/system';
import Auth from './Auth';
import UserProfile from './Account/UserProfile';
import Home from './Home';
import NavigationBar from './NavigationBar';
import Feedback from './Feedback';
import PrivateRoute from './PrivateRoute';
import { useAuth } from './hooks/useAuth';
import { useSelector, useDispatch } from 'react-redux';
import { showAIPanel, hideAIPanel, setHasViewedReport, resetHasViewedReport } from './redux/uiActions'; 
import AISurfReportContainer from './SurfReport/AISurfReportContainer';
import GoogleMaps from './Map/MapContainer';
import SurfDataContainer from './SurfReport/SurfDataContainer';
import { LoadingButton } from '@mui/lab';
import { LoadScript } from "@react-google-maps/api";
import { Icon } from '@mui/material';


const googleMapsLibraries = ["places"];

// Define the styled components that were in MapWithData
const MapWithDataWrapper = styled('div')({
  paddingBottom: '80px',
});

const GoogleMapsWrapper = styled('div')({
  position: 'relative',  // Create a stacking context
  width: '100%',
  height: '50vh',
  overflow: 'hidden',
});

function LoadingSpinner() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
        </div>
    );
}

function SurfReport({ handleReportButtonClick }) {
  const aiSurfReport = useSelector(state => state.aiSurfReport.aiSurfReport);
  const isAIPanelVisible = useSelector(state => state.ui.showAIPanel);
  const isLoadingAISurfReport = useSelector(state => state.aiSurfReport.aiSurfReportLoading);
  const isSpotChanged = useSelector(state => state.selectedSpot.isSpotChanged);
  const isLoadingSurfData = useSelector(state => state.surfData.surfDataLoading);  // Added this line
  const surfData = useSelector(state => state.surfData.surfData);  // Added this line

  const spinnerStyles = {
    marginRight: '8px',
  };

  let buttonText = "Select a spot";
  if (surfData || isLoadingSurfData) {
    if (isLoadingAISurfReport || isSpotChanged) {
      buttonText = "Fetching AI Surf Report...";
    } else if (aiSurfReport) {
      buttonText = "AI Surf Report Ready!";
    }
  }

  return (
    <div>
      <StyledLoadingButton
          loading={isLoadingAISurfReport}
          loadingPosition="start"
          startIcon={<Icon style={{ opacity: 0 }}>place</Icon>}
          loadingIndicator={<CircularProgress style={spinnerStyles} color="inherit" size={24} />}
          variant="outlined"
          onClick={handleReportButtonClick}
      >
          {buttonText}
      </StyledLoadingButton>

      <StyledSlideInPanel isVisible={isAIPanelVisible}>
        <AISurfReportContainer />
      </StyledSlideInPanel>
    </div>
  );
}


const StyledAppContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', 
  justifyContent: 'flex-start', 
  minHeight: '100vh',
  padding: '4px',
  boxSizing: 'border-box',
  overflowX: 'hidden',
  overflowY: 'auto',
});

const StyledMainContent = styled('div')({
  width: '100%',
  margin: '0 auto', 
  boxSizing: 'border-box',
  overflowY: 'auto',
});

const StyledSlideInPanel = styled('div')(({ isVisible }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: 'white',
  maxHeight: '60%', 
  overflowY: 'auto',
  zIndex: 999,
  borderTop: '1px solid #ddd',
  boxShadow: '0 -1px 10px rgba(0,0,0,0.1)',
  transform: isVisible ? 'translateY(0)' : 'translateY(100%)',
  transition: 'transform 0.3s ease-out',

}));


const StyledLoadingButton = styled(LoadingButton)({
  background: 'linear-gradient(90deg, #1C3664 0%, #0F2548 100%)',
  color: '#f5f5f5',
  position: 'fixed',
  bottom: '60px',
  left: '50%',
  width: '100%',
  maxWidth: '95vw', // Ensure button doesn't exceed the viewport width
  zIndex: 5000,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
  transform: "translateX(-50%)", // Center the button

  "&:hover, &:focus, &:active": {
    background: 'linear-gradient(90deg, #1C3664 0%, #0F2548 100%)',
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.08)',
    transform: "translateX(-50%) scale(1.02)", // Scale after translating
  },

  "&.MuiButtonBase-root.Mui-disabled": {
    color: '#f5f5f5',
    background: 'linear-gradient(90deg, #1C3664 0%, #0F2548 100%)',
  },

  "& .MuiButton-label": { 
    color: '#f5f5f5',
  },

  "@media (min-width: 768px)": {
    maxWidth: '600px',
    // Default transform for wider screens
    transform: "translateX(-50%)"
  }
});



function App() {
  const dispatch = useDispatch();
  const surfData = useSelector(state => state.surfData.surfData);
  const selectedSpot = useSelector((state) => state.selectedSpot);
  const isAIPanelVisible = useSelector(state => state.ui.showAIPanel); 
  const { loading } = useAuth();

  useEffect(() => {
    if (surfData && selectedSpot) {
      dispatch(resetHasViewedReport()); 
    }
  }, [surfData, selectedSpot, dispatch]);

  const handleReportButtonClick = useCallback(() => {
    if (isAIPanelVisible) { 
      dispatch(hideAIPanel());
      dispatch(setHasViewedReport());
    } else {
      dispatch(showAIPanel());
    }
  }, [isAIPanelVisible, dispatch]);

  return (
    <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={googleMapsLibraries}
    >
    <Router>
      <Container maxWidth="md">
        <StyledAppContainer>
          <NavigationBar />

          <StyledMainContent>
            {loading ? (
              <LoadingSpinner />
            ) : (
            <Routes>
                <Route path="/login" element={<Auth />} />
                <Route 
                  path="/private/user-profile" 
                  element={
                    <PrivateRoute>
                      <UserProfile />
                    </PrivateRoute>
                  } 
                />
                <Route path="/location/:lat/:lng" element={
                  <>
                    <SurfReport handleReportButtonClick={handleReportButtonClick} />
                    <MapWithDataWrapper>
                        <GoogleMapsWrapper>
                            <GoogleMaps />
                        </GoogleMapsWrapper>
                        <SurfDataContainer />
                    </MapWithDataWrapper>
                  </>
                } />
                    <Route 
                      path="/private/feedback" 
                      element={
                          <PrivateRoute>
                              <Feedback />
                          </PrivateRoute>
                      } 
                    />

                <Route path="/*" element={
                  <>
                    <SurfReport handleReportButtonClick={handleReportButtonClick} />
                    <Home />
                    <MapWithDataWrapper>
                        <GoogleMapsWrapper>
                            <GoogleMaps />
                        </GoogleMapsWrapper>
                        <SurfDataContainer />
                    </MapWithDataWrapper>
                  </>
                } />
            </Routes>

            )}
          </StyledMainContent>
        </StyledAppContainer>
      </Container>
    </Router>
    </LoadScript>
  );
}

export default App;