import { 
    signInWithEmailAndPassword, 
    createUserWithEmailAndPassword, 
    signInWithPopup, 
    signOut,
    GoogleAuthProvider
  } from "firebase/auth";
  import { auth } from "../firebase";
  import { 
    setUser, 
    removeUser, 
    setLoading, 
    setError, 
    clearError 
  } from './userReducer';
  
  export const emailAuth = (isLogin, email, password) => {
    return async (dispatch) => {
      dispatch(setLoading(isLogin ? 'email-login' : 'email-signup'));
      dispatch(clearError());
  
      let userCredential;
  
      try {
        if (isLogin) {
          userCredential = await signInWithEmailAndPassword(auth, email, password);
        } else {
          userCredential = await createUserWithEmailAndPassword(auth, email, password);
        }
        const { uid, email: userEmail, displayName } = userCredential.user;
        dispatch(setUser({ uid, email: userEmail, displayName }));
      } catch (error) {
        const errorMessage = isLogin ? 
            "Error signing in with password and email" : 
            "Error signing up with password and email";
        console.error(errorMessage, error);
        dispatch(setError(errorMessage));
      } finally {
        dispatch(setLoading('idle')); 
      }
    };
};

  
  export const googleAuth = () => {
    return async (dispatch) => {
      dispatch(setLoading('google-login'));
      dispatch(clearError());
  
      try {
        const googleProvider = new GoogleAuthProvider();
        const userCredential = await signInWithPopup(auth, googleProvider);
        const { uid, email, displayName } = userCredential.user;
        dispatch(setUser({ uid, email, displayName }));
      } catch (error) {
        console.error("Error signing in with Google", error);
        dispatch(setError("Error signing in with Google"));
      } finally {
        dispatch(setLoading('idle')); 
      }
    };
  };
  
  export const logoutUser = () => {
    return async (dispatch) => {
      dispatch(setLoading('logout'));
      dispatch(clearError());
  
      try {
        await signOut(auth);
        dispatch(removeUser());
      } catch (error) {
        console.error("Error signing out", error);
        dispatch(setError("Error signing out"));
      } finally {
        dispatch(setLoading('idle')); 
      }
    };
  };
  