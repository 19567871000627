import {
    SURF_DATA_REQUEST,
    SURF_DATA_SUCCESS,
    SURF_DATA_FAILURE,
    CLEAR_SURF_DATA
  } from './fetchSurfData';
  
  const initialState = {
    surfData: null,
    surfDataLoading: false,
    surfDataError: null,
  };
  
  export function surfDataReducer(state = initialState, action) {
    switch (action.type) {
      case SURF_DATA_REQUEST:
        return { ...state, surfDataLoading: true, surfDataError: null };
      case SURF_DATA_SUCCESS:
        return { ...state, surfData: action.payload, surfDataLoading: false };
      case SURF_DATA_FAILURE:
        return { ...state, surfDataError: action.payload, surfDataLoading: false };
        case CLEAR_SURF_DATA:
          return { ...initialState }; // Reset the state to its initial state
      default:
        return state;
    }
  }
  