import React from 'react';
import { useUser } from './hooks/useUser';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function Home() {
  const user = useUser();

  return (
    <Box>
      {user ? (
        <Typography variant="h6">Welcome back, {user.displayName}!</Typography>
      ) : (
        <Typography variant="h6">Sign in to check conditions at your favorite surf spots.</Typography>
      )}
      <Typography variant="body1">
        Try searching for spots like 'Waikīkī', 'Huntington Beach', or 'Cocoa Beach'.
      </Typography>
    </Box>
  );
}

export default Home;
