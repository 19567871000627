// hooks/useUser.js
import { useSelector } from 'react-redux';

/**
 * Custom hook to retrieve user data from Redux store.
 * @returns {Object} The user object from the Redux store.
 */
export const useUser = () => {
  // Utilize the useSelector hook to extract the user object from the Redux store's state.
  // The state parameter represents the entire Redux store state.
  // The returned value from this hook is the user object stored in the Redux store.
  return useSelector((state) => state.user.user);
};
