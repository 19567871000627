import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledTableContainer = styled(TableContainer)({
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)', 
  borderRadius: '8px', // Rounded corners
  overflow: 'hidden', // Hide any exceeding content
});

const HeaderDiv = styled('div')({
  fontWeight: 'bold',
  padding: '10px',
  textAlign: 'center',
  backgroundColor: '#D1DAE8', // Light blue
});

function SurfDataRenderer({ surfData }) {
  
  // Utility function to retrieve nested values safely
  const getNestedValue = (obj, path, fallback = null) => {
    return path.reduce((xs, x) => (xs && xs[x]) ? xs[x] : fallback, obj);
  }

  // Extracting data with fallbacks
  const location = getNestedValue(surfData, ['location']);
  const temperature = getNestedValue(surfData, ['temperature']);
  const wind = getNestedValue(surfData, ['wind']);
  const currentWeatherConditions = getNestedValue(surfData, ['currentWeatherConditions']);
  const waveHeight = getNestedValue(surfData, ['waveHeight', 'observed']) || getNestedValue(surfData, ['waveHeight']);
  const wavePeriod = getNestedValue(surfData, ['wavePeriod', 'observed']) || getNestedValue(surfData, ['avgWavePeriod']);
  const swellHeight = getNestedValue(surfData, ['swellHeight', 'observed']) || getNestedValue(surfData, ['swellHeight']);
  const swellDirection = getNestedValue(surfData, ['swellDirection', 'observed']) || getNestedValue(surfData, ['swellDirection']);
  const tideState = getNestedValue(surfData, ['tideState']);

  return (
    <StyledTableContainer component={Paper}>
      <HeaderDiv>Surf & Weather Conditions</HeaderDiv>
      <Table>
        <TableBody>
          {location && (
            <TableRow>
              <TableCell>Location</TableCell>
              <TableCell>{location}</TableCell>
            </TableRow>
          )}
          {temperature && (
            <TableRow>
              <TableCell>Temperature</TableCell>
              <TableCell>{temperature}</TableCell>
            </TableRow>
          )}
          {wind && (
            <TableRow>
              <TableCell>Wind</TableCell>
              <TableCell>{wind}</TableCell>
            </TableRow>
          )}
          {currentWeatherConditions && (
            <TableRow>
              <TableCell>Current Weather</TableCell>
              <TableCell>{currentWeatherConditions}</TableCell>
            </TableRow>
          )}
          {waveHeight && (
            <TableRow>
              <TableCell>Wave Height</TableCell>
              <TableCell>{waveHeight}</TableCell>
            </TableRow>
          )}
          {wavePeriod && (
            <TableRow>
              <TableCell>Wave Period</TableCell>
              <TableCell>{wavePeriod}</TableCell>
            </TableRow>
          )}
          {swellHeight && (
            <TableRow>
              <TableCell>Swell Height</TableCell>
              <TableCell>{swellHeight}</TableCell>
            </TableRow>
          )}
          {swellDirection && (
            <TableRow>
              <TableCell>Swell Direction</TableCell>
              <TableCell>{swellDirection}</TableCell>
            </TableRow>
          )}
          {tideState && (
            <TableRow>
              <TableCell>Tide State</TableCell>
              <TableCell>{tideState}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}

SurfDataRenderer.propTypes = {
  surfData: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Object)]).isRequired,
};

SurfDataRenderer.whyDidYouRender = true;

export default SurfDataRenderer;
