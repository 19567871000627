// uiReducer.js
const initialState = {
    showAIPanel: false,
    aiSurfReport: {},
    hasViewedReport: false
  };
  
  const uiReducer = (state = initialState, action) => {
    switch (action.type) {

      case 'SHOW_AI_PANEL':
        return { ...state, showAIPanel: true };
      case 'HIDE_AI_PANEL':
        return { ...state, showAIPanel: false };
      case 'SET_HAS_VIEWED_REPORT':
        return { ...state, hasViewedReport: true };
      case 'RESET_HAS_VIEWED_REPORT':
        return { ...state, hasViewedReport: false };  
      case 'SET_AI_SURF_REPORT':
        return { ...state, aiSurfReport: action.payload };

      default:
          return state;  
    }
  };
  
  export default uiReducer;
  