import { aiSurfReport } from '../firebase.js';

// Action types
export const AI_SURF_REPORT_REQUEST = 'AI_SURF_REPORT_REQUEST';
export const AI_SURF_REPORT_SUCCESS = 'AI_SURF_REPORT_SUCCESS';
export const AI_SURF_REPORT_FAILURE = 'AI_SURF_REPORT_FAILURE';
export const CLEAR_AI_DATA = 'CLEAR_AI_DATA';


// Action creators
export const aiSurfReportRequest = () => ({ type: AI_SURF_REPORT_REQUEST });
export const aiSurfReportSuccess = (data) => ({ type: AI_SURF_REPORT_SUCCESS, payload: data });
export const aiSurfReportFailure = (error) => ({ type: AI_SURF_REPORT_FAILURE, payload: error });
export const clearAIData = () => ({ type: CLEAR_AI_DATA });


// Redux thunk
export const fetchAISurfReport = (surfData) => async (dispatch) => {
  // console.log("fetchAISurfReport called");
  // console.trace();  // shows where the thunk is being called from.

  dispatch(aiSurfReportRequest());
  try {
    // List of keys to check
    const keysToSend = [
      'location',
      'localTime',
      'dominantWaves',
      'surfConditionsNarrative',
      'howAreWavesBreaking',
      'tideNarrative',
      'tideState',
      'weatherForecast',
      'currentWeatherConditions',

    ];

    // Create an object with the desired keys filtered for non-null values
    const filteredData = keysToSend.reduce((acc, key) => {
      if (surfData[key] !== 'null' && surfData[key] !== undefined) {
        acc[key] = surfData[key];
      }
      return acc;
    }, {});

    const reportData = await aiSurfReport(filteredData);
    dispatch(aiSurfReportSuccess(reportData));
    return reportData;
  } catch (error) {
    dispatch(aiSurfReportFailure(error));
  }
};

