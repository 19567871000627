import React from "react";
import { useSelector } from "react-redux";
import AISurfReportRenderer from "./AISurfReportRenderer";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

const SlideInPanel = styled('div')({
  backgroundColor: 'white',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',  // Box shadow to give it depth
  borderRadius: '8px',  // Rounded corners
  overflow: 'hidden',  // To ensure inner content doesn't exceed the rounded corners
  paddingBottom: '60px', // Padding at the bottom
  background: 'linear-gradient(90deg, #FFFFFF 0%, #D1DAE8 100%)', // start with white and transitioning to a light blue
  color: '#1C3664', // primary color
  marginTop: '20px', 
  maxWidth: '600px', // Set a max width
  marginLeft: 'auto', // center the card
  marginRight: 'auto', // center the card
});


const PanelHeader = styled('div')({
  backgroundColor: '#1C3664', // Using primary color
  color: 'white',
  textAlign: 'center',
  padding: '2px 0',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)'  // Light shadow for depth
});

const LoadingContainer = styled('div')({
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  height: '100px',
  backgroundColor: '#1C3664', // Using primary color
  padding: '20px',  // Added padding
});

function AISurfReportContainer() {
  const aiSurfReport = useSelector(state => state.aiSurfReport.aiSurfReport);
  const isAIPanelVisible = useSelector(state => state.ui.showAIPanel);

  if (!isAIPanelVisible) return null;

  return (
    <SlideInPanel>
      <PanelHeader>
        <Typography variant="h6" component="div">
          AI Surf Report
        </Typography>
      </PanelHeader>
      {aiSurfReport ? 
        <AISurfReportRenderer aiSurfReport={aiSurfReport} />
        : 
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      }
    </SlideInPanel>
  );
}

export default AISurfReportContainer;
