import React, { useState, useEffect } from "react";
import { FaGoogle } from "react-icons/fa";
import Button from "@mui/material/Button";
import { styled } from '@mui/system';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from './hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { emailAuth, googleAuth, logoutUser } from './redux/authThunk';



const CommonButtonStyles = {
  transition: 'all 0.1s ease',
  '&:hover, &:focus': {
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-2px)'
  },
};

const SwitchButton = styled(Button)(({ isPanelVisible }) => ({
  ...CommonButtonStyles,
  backgroundColor: '#FDDA11',
  color: '#1C3664', 
  marginTop: '15px',
  zIndex: 5000,
  "&:hover, &:focus, &:active": {
    ...CommonButtonStyles['&:hover, &:focus'],
    backgroundColor: '#FDDA11',
    opacity: 0.8,
    transform: 'none',
  },
}));

const StyledSubmitButton = styled(Button)({
  ...CommonButtonStyles,
  background: 'linear-gradient(90deg, #1C3664 0%, #0F2548 100%)',
  color: '#f5f5f5',
  marginTop: '15px',
  marginBottom: '10px', // spacing added
  "&:hover, &:focus, &:active": {
    background: 'linear-gradient(90deg, #1C3664 0%, #0F2548 100%)',
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.08)',
  },
});

const StyledGoogleButton = styled(Button)({
  ...CommonButtonStyles,
  background: 'white', 
  color: '#1C3664', 
  border: '2px solid #1C3664',
  marginBottom: '10px', 
  "&:hover, &:focus, &:active": {
    background: '#f5f5f5', 
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.08)',
  },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: 400
}));

const StyledTextField = styled(TextField)({
  width: '100%',
  backgroundColor: 'white',
  marginBottom: '15px', // Add this line to create space
  '& .MuiInputLabel-outlined': {
    color: 'rgba(0, 0, 0, 0.75)',
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(12px, -3px) scale(0.8)',
  },
});



function Auth() {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();
  const user = useUser();
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const switchAuthModeHandler = () => {
    setIsLogin((prevState) => !prevState);
  };

  const handleLogout = () => {
    dispatch(logoutUser()); // Using the logoutUser thunk
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(emailAuth(isLogin, email, password)); // Using the emailAuth thunk
  };
  
  const signInWithGoogle = () => {
    dispatch(googleAuth()); // Using the googleAuth thunk
  };

  useEffect(() => {
    if (user) {
      let currentPath = window.location.pathname;
      if (currentPath !== '/') {
        navigate('/');
      }
    }
  }, [user, navigate]);
  let emailButtonLabel;
  if (loading === 'email-login') {
      emailButtonLabel = "Signing in...";
  } else if (loading === 'email-signup') {
      emailButtonLabel = "Signing up...";
  } else {
      emailButtonLabel = isLogin ? "Login" : "Sign Up";
  }

  let googleButtonLabel = loading === 'google-login' ? "Signing in with Google..." : "Sign In with Google";
  let logoutButtonLabel = loading === 'logout' ? "Logging out..." : "Logout";

  return (
    <Box className="auth-container" display="flex" flexDirection="column" alignItems="center">
      {user ? (
        <StyledSubmitButton
          onClick={handleLogout}
          disabled={loading !== 'idle'}
          fullWidth
        >
          {logoutButtonLabel}
        </StyledSubmitButton>
      ) : (
        <StyledPaper elevation={3}>
          <h1>{isLogin ? "Login" : "Sign Up"}</h1>
          <form className="auth-form" onSubmit={handleSubmit}>
            {!isLogin && (
              <StyledTextField
                label="Name"
                variant="outlined"
                fullWidth
              />
            )}
            <StyledTextField
              label="Email"
              variant="outlined"
              fullWidth
              onChange={handleEmailChange}
            />
            <StyledTextField
              label="Password"
              variant="outlined"
              fullWidth
              type="password"
              onChange={handlePasswordChange}
            />
            <StyledSubmitButton
              type="submit"
              fullWidth
              disabled={loading !== 'idle'}
            >
              {emailButtonLabel}
            </StyledSubmitButton>
            
            <StyledGoogleButton
              onClick={signInWithGoogle}
              fullWidth
              disabled={loading !== 'idle'}
              startIcon={<FaGoogle />}
            >
              {googleButtonLabel}
            </StyledGoogleButton>
            
            <SwitchButton
              onClick={switchAuthModeHandler}
              fullWidth
              disabled={loading !== 'idle'}
            >
              Switch to {isLogin ? "Sign Up" : "Login"}
            </SwitchButton>
          </form>
        </StyledPaper>
      )}
    </Box>
);

  
}

export default Auth;
