import React, { useEffect, useState } from 'react';
import { useUser } from '../hooks/useUser';

function UserProfile() {
  const user = useUser();

  // is user data available?
  if (!user) {
    return <p>No user data available</p>;
  }

  return (
    <div className="user-profile">
      <p>{user.displayName}</p>
      <p>{user.email}</p>
      {/* Add more user fields as necessary */}
    </div>
  );
}

export default UserProfile;

