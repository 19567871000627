import React, { useCallback } from "react";
import PropTypes from "prop-types";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useDispatch } from "react-redux";
import { setMapOptions } from "../redux/mapOptionsReducer";
import { TextField, ListItem, List, styled } from "@mui/material";

// Function to trim coordinates to a fixed decimal point
function trimCoordinates(coord) {
  return Math.round(coord * 10000) / 10000;
}

// Styled component for the search box container
const SearchBoxContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 999,
});

// Styled component for the suggestions list
const SuggestionsList = styled(List)({
  position: 'absolute',
  top: '100%',
  left: 0,
  zIndex: 1001,
  backgroundColor: 'white',
  width: '100%',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
});

// Styled component for the text field
const StyledTextField = styled(TextField)({
  width: '100%',
  backgroundColor: 'white',
  '& .MuiInputLabel-outlined': {
    color: 'rgba(0, 0, 0, 0.75)',
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(12px, -3px) scale(0.8)',
  },
});

function SearchBox({ fetchSurfDataAndNavigate }) {
  const dispatch = useDispatch();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  // Callback for handling text input changes
  const handleInput = useCallback((e) => {
    setValue(e.target.value);
  }, [setValue]);

  // Callback for handling suggestion selection
  const handleSelect = useCallback(async ({ description }) => {
    setValue(description, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address: description });
      const { lat: rawLat, lng: rawLng } = await getLatLng(results[0]);
      const lat = trimCoordinates(rawLat);
      const lng = trimCoordinates(rawLng);

      dispatch(
        setMapOptions({
          center: { lat, lng },
          zoom: 15,
        })
      );

      fetchSurfDataAndNavigate(lat, lng);
    } catch (error) {
      console.log("Error: ", error);
    }
  }, [dispatch, fetchSurfDataAndNavigate, setValue, clearSuggestions]);

  // Function to render suggestion items
  const renderSuggestions = () =>
    data.map((suggestion) => (
      <ListItem
        key={suggestion.place_id}
        onClick={() => handleSelect(suggestion)}
      >
        {suggestion.description}
      </ListItem>
    ));

  return (
    <SearchBoxContainer>
      {/* Search TextField */}
      <StyledTextField
        value={value}
        onChange={handleInput}
        disabled={!ready}
        fullWidth
        variant="outlined"
        label="Search for a surf spot"
      />
      {/* Suggestions List */}
      {status === "OK" && (
        <SuggestionsList>
          {renderSuggestions()}
        </SuggestionsList>
      )}
    </SearchBoxContainer>
  );
}

SearchBox.propTypes = {
  fetchSurfDataAndNavigate: PropTypes.func.isRequired,
};

export default React.memo(SearchBox);
