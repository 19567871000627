import { useEffect } from 'react';

function Feedback() {
  useEffect(() => {
    // Redirect to the mailto link for feedback
    window.location.href = "mailto:dev@rideai.app?subject=RideAI App Feedback";

    // Set a timeout to redirect back to the homepage after a brief delay
    setTimeout(() => {
      window.location.replace('/');  // Redirect to the homepage
    }, 1000); // You can adjust the delay time (in milliseconds) as needed
  }, []);
  
  return null;  // This component doesn't render anything visually
}

export default Feedback;
