import { getSurfData } from "../firebase"; 

// Action types
export const SURF_DATA_REQUEST = 'SURF_DATA_REQUEST';
export const SURF_DATA_SUCCESS = 'SURF_DATA_SUCCESS';
export const SURF_DATA_FAILURE = 'SURF_DATA_FAILURE';
export const CLEAR_SURF_DATA = 'CLEAR_SURF_DATA';

// Action creators
export const surfDataRequest = () => ({ type: SURF_DATA_REQUEST });
export const surfDataSuccess = (data) => ({ type: SURF_DATA_SUCCESS, payload: data });
export const clearSurfData = () => ({ type: CLEAR_SURF_DATA });
export const surfDataFailure = (error) => ({ type: SURF_DATA_FAILURE, payload: error.message }); 


// Redux thunk
export const fetchSurfData = (lat, lng) => async (dispatch) => {
  dispatch(surfDataRequest());
  try {
    const result = await getSurfData({ lat, lng }); 
    const surfData = result.data;

    if (surfData) {
      dispatch(surfDataSuccess(surfData));
      return surfData;
    } else {
      throw new Error('Error fetching surf data');
    }
  } catch (error) {
    dispatch(surfDataFailure(error));
  }
};


