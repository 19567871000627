// src/redux/mapOptionsReducer.js - Reducer for map options: zoom, etc
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  center: { lat: 33.384, lng: -117.5929 },
  zoom: 10,
};

const mapOptionsSlice = createSlice({
  name: 'mapOptions',
  initialState,
  reducers: {
    setMapOptions: (state, action) => {
      state.center = action.payload.center;
      state.zoom = action.payload.zoom;
    },
  },
});

export const { setMapOptions } = mapOptionsSlice.actions;

export default mapOptionsSlice.reducer;
