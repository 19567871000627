import { configureStore } from '@reduxjs/toolkit';
import spotsReducer from './spotsReducer';
import userReducer from './userReducer';
import mapOptionsReducer from './mapOptionsReducer';
import selectedSpotReducer from './selectedSpotReducer';
import { surfDataReducer } from './surfDataReducer';
import { aiSurfReportReducer } from './aiSurfReportReducer';
import uiReducer from './uiReducer';

const store = configureStore({
  reducer: {
    spots: spotsReducer,
    user: userReducer,
    mapOptions: mapOptionsReducer,
    selectedSpot: selectedSpotReducer,
    surfData: surfDataReducer,
    aiSurfReport: aiSurfReportReducer,
    ui: uiReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
