import React from "react";
import PropTypes from "prop-types";

function AISurfReportRenderer({ aiSurfReport }) {
  // Extracting the "outputText" value
  const outputText = aiSurfReport?.data?.outputText || "";

  return (
    <div style={{ padding: '20px', lineHeight: '1.6', fontSize: '16px', maxWidth: '800px', margin: '0 auto' }}>
      {outputText.split("\n\n").map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div>
  );
}

AISurfReportRenderer.propTypes = {
  aiSurfReport: PropTypes.object,
};

AISurfReportRenderer.defaultProps = {
  aiSurfReport: null,
};

export default AISurfReportRenderer;
