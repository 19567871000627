// This component renders a marker on a map and manages interactions with an info window for a given surf spot.
import React from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import PropTypes from "prop-types";

function MarkerWithInfoWindow({
  spot, onMarkerClick, isSelected, onCloseClick,
}) {
  // Function to handle the marker click event
  const handleMarkerClick = () => {
    // Calls the provided onMarkerClick function with the spot's latitude and longitude
    onMarkerClick(spot.lat, spot.lng);
  };

  return (
    <Marker
      position={{ lat: spot.lat, lng: spot.lng }}
      onClick={handleMarkerClick}
    >
      {/* Conditionally render an InfoWindow if the marker is selected */}
      {isSelected && (
        <InfoWindow
          position={{ lat: spot.lat, lng: spot.lng }}
          onCloseClick={onCloseClick}
        >
          {/* Display the spot's label in the InfoWindow */}
          <div>{spot.label}</div>
        </InfoWindow>
      )}
    </Marker>
  );
}

// Define PropTypes to document the expected props and their types
MarkerWithInfoWindow.propTypes = {
  spot: PropTypes.shape({
    label: PropTypes.string.isRequired, // The label of the surf spot (string)
    lat: PropTypes.number.isRequired,   // The latitude of the surf spot (number)
    lng: PropTypes.number.isRequired,   // The longitude of the surf spot (number)
  }).isRequired,
  onMarkerClick: PropTypes.func.isRequired, // Function to handle marker click (function)
  isSelected: PropTypes.bool, // Not required
  onCloseClick: PropTypes.func.isRequired,
};

export default MarkerWithInfoWindow;
