import React, { useRef, useEffect, useCallback } from "react";
import { GoogleMap } from "@react-google-maps/api"; 
import { useNavigate } from "react-router-dom";
import SearchBox from "./SearchBox";
import MarkerWithInfoWindow from "./MapMarker";
import { useSelector, useDispatch } from "react-redux";
import { setMapOptions } from "../redux/mapOptionsReducer";
import { setSelectedSpot, clearSelectedSpot, resetSpotChanged } from "../redux/selectedSpotReducer";
import { fetchSurfData, clearSurfData } from '../redux/fetchSurfData';
import { fetchAISurfReport, clearAIData } from '../redux/fetchAISurfReport';
import { hideAIPanel } from '../redux/uiActions';
import { styled } from '@mui/system';

// Styling for the Google Map container
const containerStyle = {
    width: "100%",
    height: "400px",
};

// Styled container for the Google Map
const StyledGoogleMapContainer = styled('div')({
    width: '100%',
    height: '100%',
    marginBottom: '16px',
});

// Helper function to trim coordinates
function trimCoordinates(coord) {
    return Math.round(coord * 10000) / 10000;
}

// Main component for the Google Maps functionality
function GoogleMaps() {

    // Redux state management
    const mapOptions = useSelector((state) => state.mapOptions);
    const { center, zoom } = mapOptions;

    const mapRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedSpot = useSelector((state) => state.selectedSpot);
    const spots = useSelector((state) => state.spots);

    // Function to fetch surf data and navigate to a location
    const fetchSurfDataAndNavigate = useCallback((lat, lng) => {
        const trimmedLat = trimCoordinates(lat);
        const trimmedLng = trimCoordinates(lng);
    
        // Clear AI-related data and UI actions
        dispatch(hideAIPanel());
        dispatch(clearSurfData());
        dispatch(clearAIData());
    
        // Update the selected spot
        dispatch(setSelectedSpot({ lat: trimmedLat, lng: trimmedLng }));
    
        // Fetch surf data and AI report
        dispatch(fetchSurfData(trimmedLat, trimmedLng))
            .then(surfData => {
                if (surfData) {
                    dispatch(fetchAISurfReport(surfData))
                      .then(() => {
                          // Reset isSpotChanged to false once AI surf report data is fetched
                          dispatch(resetSpotChanged());
                      });
                }
            });
    
        // Navigate to the location
        navigate(`/location/${trimmedLat}/${trimmedLng}`);
    }, [dispatch, navigate]);
    
    
    

    // Function to handle marker click
    const handleMarkerClick = useCallback((lat, lng) => {
        const trimmedLat = trimCoordinates(lat);
        const trimmedLng = trimCoordinates(lng);

        // Fetch surf data and navigate to the location
        fetchSurfDataAndNavigate(trimmedLat, trimmedLng);

        // Update map options
        dispatch(setMapOptions({ center: { lat: trimmedLat, lng: trimmedLng }, zoom: 14 }));
    }, [dispatch, fetchSurfDataAndNavigate]);

    // Function to handle right-click on the map
    const onMapRightClick = useCallback((e) => {
        const { latLng } = e;
        const rawLat = latLng.lat();
        const rawLng = latLng.lng();
        const trimmedLat = trimCoordinates(rawLat);
        const trimmedLng = trimCoordinates(rawLng);

        // Fetch surf data and navigate to the location
        fetchSurfDataAndNavigate(trimmedLat, trimmedLng);
    }, [fetchSurfDataAndNavigate]);

    // Effect to update map center when Redux state changes
    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.panTo(center);
        }
    }, [center]);

    // Render the Google Map component
    return (
        <StyledGoogleMapContainer>
            <SearchBox fetchSurfDataAndNavigate={fetchSurfDataAndNavigate} />
      
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                onRightClick={onMapRightClick}
                zoom={zoom}
                onLoad={(map) => {
                    mapRef.current = map;
                }}
            >
                {/* Render markers with info windows */}
                {[...spots.popularSpots, ...spots.userSpots].map((spot) => (
                    <MarkerWithInfoWindow
                        key={`${spot.lat}-${spot.lng}`}
                        spot={spot}
                        onMarkerClick={() => handleMarkerClick(spot.lat, spot.lng)}
                        isSelected={selectedSpot && selectedSpot.lat === spot.lat && selectedSpot.lng === spot.lng}
                        onCloseClick={() => dispatch(clearSelectedSpot())}
                    />
                ))}
            </GoogleMap>
        </StyledGoogleMapContainer>
    );
}

// GoogleMaps.whyDidYouRender = true;

export default React.memo(GoogleMaps);