import React from 'react';
import { useDispatch } from 'react-redux';
import { useUser } from './hooks/useUser';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';
import { removeUser } from './redux/userReducer';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import logo from './logo.svg'; //  the logo is in the same directory, should probably move assets somewhere else

const StyledAppBar = styled(AppBar)({
  background: 'linear-gradient(90deg, #1C3664 0%, #0F2548 100%)',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)'
});

const StyledButton = styled(Button)({
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  }
});

const LogoImage = styled('img')({
  height: '32px',
  cursor: 'pointer' // Add cursor pointer to indicate it's clickable
});

function MyNavbar() {
  const dispatch = useDispatch();
  const currentUser = useUser();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      dispatch(removeUser());
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  // Updated handleFeedback function
  const handleFeedback = () => {
    // Redirects to default mail client with pre-filled content
    window.location.href = "mailto:dev@rideai.app?subject=RideAI App Feedback";
  };

  return (
    <>
      <StyledAppBar position="fixed">
        <Toolbar variant="dense">
          {/* Wrap the logo with a RouterLink */}
          <RouterLink to="/">
            <LogoImage src={logo} alt="RideAI logo" />
          </RouterLink>
          <Box display="flex" marginLeft="auto">
              {/* Commenting out for now
              <StyledButton color="inherit" component={RouterLink} to="/">
                Home
              </StyledButton>
              {currentUser && (
                <StyledButton color="inherit" component={RouterLink} to="/private/user-profile">
                  Profile
                </StyledButton>
              )}
              */}
              <StyledButton color="inherit" onClick={handleFeedback}>
                Feedback
              </StyledButton>

              {currentUser ? (
                <StyledButton color="inherit" onClick={handleLogout}>
                  Logout
                </StyledButton>
              ) : null /* Commented out Login feature */ }
            </Box>
          </Toolbar>
        </StyledAppBar>
        <Container style={{ paddingTop: 56 }}>
          {/* other content goes here */}
        </Container>
      </>
    );
  }

  export default MyNavbar;