/* NOT the same as spotsReducer! This one manages a single spot -- the spot
the user currently has selected. It's a single spot object, not a list
Actions to set the selected spot and clear the selected spot. state can be used to display
info about the spot or perfom actions specifically on that spot: get a report, save it, etc... */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  spot: null,
  isSpotChanged: false
};

const selectedSpotSlice = createSlice({
  name: 'selectedSpot',
  initialState,
  reducers: {
    setSelectedSpot: (state, action) => {
      state.spot = action.payload;
      state.isSpotChanged = true; // Set isSpotChanged to true whenever a new spot is selected
    },
    clearSelectedSpot: () => {
      return { ...initialState, isSpotChanged: true }; // Retain the isSpotChanged value when clearing the spot
    },
    resetSpotChanged: (state) => {
      state.isSpotChanged = false;
    }
  }
  
});

export const { setSelectedSpot, clearSelectedSpot, resetSpotChanged } = selectedSpotSlice.actions;
export const selectIsSpotChanged = state => state.selectedSpot.isSpotChanged;

export default selectedSpotSlice.reducer;
