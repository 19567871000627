import React from "react";
import { useSelector } from "react-redux";
import SurfDataRenderer from "./SurfDataRenderer";
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  backgroundColor: '#f5f5f5', // An example background color
  padding: '20px',           // Some padding
  borderRadius: '8px',       // I think this is consistent w/ the theme
});

const StyledMessage = styled('div')({
  color: '#1C3664',  // primary color
  fontSize: '16px',
  textAlign: 'center',
});

function SurfDataContainer() {
    const surfData = useSelector(state => state.surfData.surfData);
    const isLoadingSurfData = useSelector(state => state.surfData.surfDataLoading);
    const selectedSpot = useSelector(state => state.selectedSpot.spot);

    let content;

    if (isLoadingSurfData) {
        content = <CircularProgress />;
    } else if (!surfData && selectedSpot) {
        content = <StyledMessage>No surf data available for the selected spot.</StyledMessage>;
    } else if (!surfData) {
        content = <StyledMessage>Select a spot to see surf data.</StyledMessage>;
    } else {
        content = <SurfDataRenderer surfData={surfData} />;
    }

    return <StyledContainer>{content}</StyledContainer>;
}

export default SurfDataContainer;
