import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    loading: 'idle',
    error: null,  // Added error state
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.loading = 'idle';
      state.error = null; 
    },
    removeUser: (state) => {
      state.user = null;
      state.loading = 'idle';
      state.error = null; 
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => { 
      state.error = action.payload;
      state.loading = false;
    },
    clearError: (state) => {
      state.error = null;
    }
  },
});

export const { setUser, removeUser, setLoading, setError, clearError } = userSlice.actions;

export default userSlice.reducer;
