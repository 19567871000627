/* responsible for managing a list of spots, both from the popular list and user added. 
The state managed by this reducer is a list
and it has actions to add and remove spots from the user's list of spots.
 The state from this reducer could be used to display a list of all spots
  or to iterate over all spots for something?. */
import { createSlice } from '@reduxjs/toolkit'
import popularSpots from "../Map/PopularSpots";

const spotsSlice = createSlice({
  name: 'spots',
  initialState: {
    popularSpots: popularSpots,
    userSpots: [],
  },
  reducers: {
    addSpot: (state, action) => {
      state.userSpots = [...state.userSpots, action.payload];
  },  
    removeSpot: (state, action) => {
      state.userSpots = state.userSpots.filter(spot => spot.id !== action.payload.id);
    },    
  },
})

export const { addSpot, removeSpot } = spotsSlice.actions

export default spotsSlice.reducer
